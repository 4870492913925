import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { colors, breakpoints, fonts } from '../style-utilities/variables';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

const StyledAwards = styled.div`
  margin: 2em 0 5em;
  .center {
    margin-bottom: 1em;
    text-align: center;
    @media (min-width: ${breakpoints.tablet}) {
      .center {
        margin: 2em 0 1.5em
      }
    }
  }
  .awards-container {
    @media (min-width: ${breakpoints.tablet}) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .award {
      max-width: 210px;
      padding: 10px;
      text-align: center;
      margin: 0 auto;
      a {
        color: black;
      }
      .img-cont {
        max-width: 200px;
        margin: 0 auto;
        padding: 0 10px;
        img {
          min-width: 140px;
          width: 100%;
        }
      }
    }
  }
`;

const Awards = ({ data }) => {
  let result = true;
  data.map(item => {
    !item.award_headline &&
    !item.award_copy &&
    !item.award_link &&
    !item.award_image ? result = false : result = true;    
  });
  return result === false ? null : (
  <StyledAwards>
    <h2 className="center">Kudos to Us</h2>
    <div className="awards-container">
      {data.map(item => {
        const AwardsHeadline = () => !item.award_headline ? null : (<h4>{item.award_headline}</h4>);
        const AwardsCopy = () => !item.award_copy ? null : <>{ReactHtmlParser(item.award_copy)}</>;
        const AwardsLink = () => !item.award_link ? null : (
          <a className="award-link" href={item.award_link}>
            <AwardsImage/>
            <AwardsHeadline/>
            </a>);
        const AwardsImage = () => !item.award_image ? null : !item.award_image.source_url ? null : (
          <div className="img-cont"><img src={item.award_image.source_url}/></div>);
          
        return (
        !item ? null : item.award_link ? (
          <div className="award">
            <AwardsLink/>
            <AwardsCopy/>
          </div>
        ) : (
          <div className="award">
            <AwardsImage/>
            <AwardsHeadline/>
            <AwardsCopy/>
          </div>
        ));
      })}
    </div>
  </StyledAwards>
  )

};

export default Awards;
