import styled from 'styled-components';
import { breakpoints, colors, fonts } from '../../style-utilities/variables';
export default styled.div`
  display: flex;
  flex-direction: column;
  border: solid 2px ${colors.secondaryGray};
  background-color: ${colors.white};
  margin: 0 auto;

  @media (min-width: ${breakpoints.tablet}) {
    max-width: 35rem;
  }
  @media (min-width: ${breakpoints.desktop}) {
    max-width: 30rem;
  }
  .card-image {
    background-color: ${colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      margin-bottom: 0;
    }
  }

  #client-logo {
    width: 5rem;
    top: 0.3rem;
    right: 0.3rem;
  }
  #client-logo-noImage {
    width: 100%;
  }
  .card-content {
    padding: 1em;
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    .clickableOnly {
      h2 {
        color: ${colors.black};
        font-family: ${fonts.subHead};
      }
    }
    .clickableOnly:hover {
      text-decoration-color: black; 
      
    }
    .category {
      margin-bottom: 5px;
    }
  }
  .card-client {
    margin-bottom: 0.5rem;
    @media (min-width: ${breakpoints.tablet}) {
      margin-bottom: 0.75rem;
    }
  }

  .card-excerpt {
    flex-grow: 1;
    margin-bottom: 1em;
    .clickableOnly,
    p {
      color: ${colors.primaryGray};
    }
    .clickableOnly:hover {
      text-decoration: none;
    }
  }

  #viewCaseStudy {
    background: ${colors.white};
    border-color: ${colors.black};
    padding: 8px 16px;
    letter-spacing: 1px;
    color: ${colors.black};

    @media (min-width: ${breakpoints.desktop}) {
      padding: 12px 25px;
      border-radius: 25px;
      letter-spacing: 3px;
    }
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .card-image {
      img {
        max-height: 320px;
      }
    }
    .card-excerpt {
      max-width: 100%;
    }
    .clickableOnly {
      max-width: 100%;
    }
  }
`;
