import React from "react";
import styled from "styled-components";
import { colors, fonts, breakpoints } from "../../style-utilities/variables";
import { Link } from "gatsby";
import RelatedGridItem from "../Related/RelatedGridItem";
import ButtonRed from "../../components/ButtonRed";

const StyledIntroduction = styled.div`
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: 86px auto 88px;
  grid-template-rows: auto;
  justify-items: center;
  margin-bottom: 1rem;
  @media (min-width: ${breakpoints.desktop}) {
    width: 100%;
  }
  .client-cont {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1rem 1fr 1rem 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-row-gap: 1.5rem;
    @media (min-width: ${breakpoints.tablet}) {
      width: 100%;
    }
    @media (min-width: ${breakpoints.desktop}) {
      /* //max-width: 65rem; */
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 1rem;
    }
    div:nth-child(1) {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }
    div:nth-child(2) {
      -ms-grid-row: 1;
      -ms-grid-column: 3;
    }
    div:nth-child(3) {
      -ms-grid-row: 1;
      -ms-grid-column: 5;
    }
  }
  .buttonWrapper {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    text-align: center;
    span {
      margin-top: 0;
    }
    margin: 3em auto;
  }
`;

const StyledTitle = styled.h2`
  width: 100%;
  font-weight: 700;
  font-size: 40px;
  color: ${colors.black};
  margin: 3em auto 1em;
  text-align: center;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
`;

const Results = ({ header, items, readAll, readAllLink, gridCardType }) => {
  var path = {
    news: "/about/news/",
    post: "/insights/blog/",
    white_paper: "/insights/white-papers/",
    case_study: "/results/case-studies/",
    video: "/insights/video/"
  };
  var buttonLabel = {
    news: "Read More",
    post: "Read More",
    white_paper: "Read More",
    case_study: "Read More",
    video: "Watch Now"
  };

  return (
    <StyledIntroduction>
      <StyledTitle>{header}</StyledTitle>
      <div className="client-cont">
        {items.slice(0, 3).map((node, index) => {
          let post_type = !node.type && node.video_type ? 'video' : path[node.type];
          return (
            <RelatedGridItem
              key={node.slug}
              item={node}
              index={index}
              path={post_type === 'video' ? path.video : path[node.type]}
              buttonLabel={post_type === 'video' ? buttonLabel.video : buttonLabel[node.type]}
              gridCardType={gridCardType ? gridCardType : "showTags"}
              tagCategory={node.type}
              text={post_type === 'video' ? node.acf.primary_topic : null}
            />
          );
        })}
      </div>
      {readAll ? (
        <div className="buttonWrapper">
          <ButtonRed textLabel={readAll} pageLink={readAllLink} />
        </div>
      ) : (
        <></>
      )}
      <div />
    </StyledIntroduction>
  );
};

export default Results;
