import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import ReactHtmlParser from "react-html-parser";
import { breakpoints, colors, fonts } from '../style-utilities/variables';

const StyledTag = styled.span`
  color: ${colors.blue};
  display: inline-block;
  font-weight: bold;
  letter-spacing: 2px;
  margin-right: 5px;
  padding: 0;
  text-transform: uppercase;
`;

var categoryLabel = {news:"News", post:'Blog', white_paper:"White Paper", case_study: 'Case Study'};

const Tag = ({ id, textColor, borderColor, color, text, tagCategory }) => {
  var tagText = tagCategory ? categoryLabel[tagCategory] : text;
  return (
    <StyledTag
      className="tag"
      id={id}
    >
      {ReactHtmlParser(tagText)}
    </StyledTag>
  );
};

export default Tag;
