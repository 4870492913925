import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { colors, breakpoints, fonts } from '../style-utilities/variables';
import styled from 'styled-components';

const StyledGlobalOurPartners = styled.div`
  text-align: center;
  margin-bottom: -5rem;
  padding: 5rem 0;
  
  h2 {
    margin-bottom: 40px;
  }
  ul {
    text-align: center;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    li {
      text-align: center;
      padding: 1rem;
      width: 100%;
      img {
        width: 260px;
      }
    }
  }
  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: -8rem;
    padding: 8rem 0 0;
    ul li {
      width: 25%;
    }
  }
`;

const GlobalOurPartners = ({ headline, logos }) => {
  return (
    <>
      <StyledGlobalOurPartners>
        <h2>{headline}</h2>
        <ul>
          {logos.map(item => {
            return (
              <li>
                <img src={item.source_url} alt="" />
              </li>
            );
          })}
        </ul>
      </StyledGlobalOurPartners>
    </>
  );
};

export default GlobalOurPartners;