import React, { Fragment, Component } from 'react';
import _ from 'lodash';
import { Link, graphql } from 'gatsby';
import { colors, breakpoints, fonts } from '../style-utilities/variables';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import ModalVideo from 'react-modal-video';
//import components
import NavBar from '../components/NavBar';
import RelatedContentTechnology from '../components/Technology/RelatedContentTechnology';
import DefaultSliderSolutionsDynamic from "../components/TabSliders/DefaultSlider/DefaultSliderSolutionsDynamic";
import DemoFormFormikVertical from '../components/DemoFormFormikVertical';
import Hero from '../components/Hero';
import Awards from '../components/Awards';

import SEO from '../components/SEO';
import DefaultSharingImage from './../static/ansira-logo-sharing.png';
import GlobalOurClients from '../components/GlobalOurClients';
import GlobalOurPartners from '../components/GlobalOurPartners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { render } from 'enzyme';
import ButtonGrey from "../components/ButtonGrey";


const SingleVerticalPage = styled.main`
  margin: 0.75em auto;
  margin-top: 0;
  overflow-x: hidden;
  .img-section {
    display: none;
    @media (min-width: 992px) {
      display: block;
      padding: 65px 0 40px;
    }
  }
  .hero-container {
    padding-left: 0;
    .pr-0 .hero-container {
      padding-right: 0;
      h1 {
        @media (min-width: 992px) and (max-width: ${breakpoints.laptopLG}) {
          font-size: 4.95vw;
        }
      }
    }
  }
  .intro-copy{
    width:100%;
  }
  .centered {
    text-align: center;
  }
  .by-the-numbers {
    text-align: center;
    .numbers-headline {
      margin-bottom: 60px;
    }
    .numbers-items-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .numbers-items {
        width: 50%;
        margin-bottom: 60px;
        @media (min-width: ${breakpoints.tabletMax}) {
          width: 23%;
        }
        .numbers-number {
          color: ${colors.primary};
          text-transform: uppercase !important;
          font-size: 28px !important; /* changed from font-size: 32px; for just this page */
          font-weight: bold;
          line-height: 1.15em;
          max-width: 220px;
          margin: 0 auto 15px;
          @media (min-width: ${breakpoints.mobileMax}) {
            font-size: 50px;
          }
        }
        .numbers-item {
          color: ${colors.black};
          font-size: 18px !important; /* changed from font-size: 22px; for just this page */
          font-weight: bold;
          line-height: 1.25em;
          max-width: 220px;
          margin: 0 auto;
        }
      }
    }
    @media (min-width: ${breakpoints.tablet}) {
      body {color: red}
    }
  }
  h3 {
    color: ${colors.primary};
    text-transform: none;
    font-family: 'daytona-bold';
    display: inline-block;
    padding-right: 10px;
  }
  .clickable {
    font-family: 'daytona-bold';
    font-size: 26px;
    border-bottom: 3px solid black;
    svg {
      margin-left: 5px;
      width: 10px;
      height: 26px;
    }
  }

  .page-container {
    max-width: 1500px;
    margin: 0 auto 5rem;
    &.full-width {
      max-width: 100%;
      padding:0;
      .page-container {
        margin: 0 auto;
      }
    }
  }

  .post-area {
    padding-top: 0rem;
    .page-container.row {
      justify-content: center;
    }
    @media (max-width: ${breakpoints.mobileMax}) {
      .blog-item .list-unstyled {
        margin-left: 0;
      }
    }
  }
  .img-txt-2-col {
    margin: 80px 0;
  }
  p:nth-child(odd) {
    margin-bottom: 0;
  }
  .third {
    font-size: 22px;
    width: 70%;
  }
  .bullet-container a {
    color: ${colors.primaryGray};
  }
  @media (min-width: ${breakpoints.tablet}) {
    .hero-container {
      padding-left: 0;
    }
    .bullet-container ul {
      width: 33%;
    }
    h1 {
      margin-bottom: 80px;
    }
    .img-txt-2-col {
      display: flex;
      margin: 100px 0;
      .image-left {
        width: 66%;
        padding-right: 8px;
      }
      .right {
        width: 33%;
      }
    }
  }
  @keyframes modal-video {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes modal-video-inner {
    from {
      transform: translate(0, 100px);
    }

    to {
      transform: translate(0, 0);
    }
  }

  .modal-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000000;
    cursor: pointer;
    opacity: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -ms-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
  }

  .modal-video-effect-exit {
    opacity: 0;

    & .modal-video-movie-wrap {
      -webkit-transform: translate(0, 100px);
      -moz-transform: translate(0, 100px);
      -ms-transform: translate(0, 100px);
      -o-transform: translate(0, 100px);
      transform: translate(0, 100px);
    }
  }

  .modal-video-body {
    max-width: 940px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: table;
  }

  .modal-video-inner {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
  }

  .modal-video-movie-wrap {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    background-color: #333;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video-inner;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -moz-transition: -moz-transform 0.3s ease-out;
    -ms-transition: -ms-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;

    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .modal-video-close-btn {
    position: absolute;
    z-index: 2;
    top: -35px;
    right: 0;
    display: inline-block;
    width: 35px;
    height: 35px;
    overflow: hidden;
    border: none;
    background: transparent;
    @media (min-width: 1000px) {
      right: -35px;
    }
    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: #fff;
      border-radius: 5px;
      margin-top: -6px;
    }
  }
`;

const StyledClients = styled(GlobalOurClients)`
  padding-top: 0rem;
`;

class VerticalPageTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewPayload: null,
      isPreviewMode: false,
      isBrowser: true,
      emailAddress: '',
      firstName: '',
      lastName: '',
      success: false,
      error: false,
      isOpen: false
    };
    // bind functions
    this.openModal = this.openModal.bind(this);
  }

    componentDidMount() {
      this.setState({
        isBrowser: typeof window !== 'undefined'
      });
      if (this.state.isBrowser) {
        let vh = window.innerHeight * 0.01;
        //set view height as a css variable to oiptimize mobile browser sizing
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        // We listen to the resize event
        window.addEventListener('resize', () => {
          // We execute the same script as before
          document.documentElement.style.setProperty('--vh', `${vh}px`);
        });
      }
    }

    // open modal (set isOpen, false)
    openModal() {
      this.setState({
        isOpen: true
      });
    }


    parseVideo(url) {
      // - Supported YouTube URL formats:
      //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
      //   - http://youtu.be/My2FRPA3Gf8
      //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
      // - Supported Vimeo URL formats:
      //   - http://vimeo.com/25451551
      //   - http://player.vimeo.com/video/25451551
      // - Also supports relative URLs:
      //   - //player.vimeo.com/video/25451551

      url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/);

      if (RegExp.$3.indexOf('youtu') > -1) {
          var type = 'youtube';
      } else if (RegExp.$3.indexOf('vimeo') > -1) {
          var type = 'vimeo';
      }

      return {
          type: type,
          id: RegExp.$6
      };
  }


  optionalImageSection(postAcf) {

    if (!postAcf.image_left) {
      return '';
    } else {
      return postAcf.image_left.source_url.includes('transparent') ? ( null ) : (
        <div className="img-txt-2-col">
          <div className="image-left">
            <img src={postAcf.image_left.source_url} alt="" />
          </div>
          <div className="right">
            <h3 className="headline">{postAcf.right_headline}</h3>
            {postAcf.right_body.map(item => {
              return (
                <div className="right-body">
                  <h4>{item.sub_headline}</h4>
                  {ReactHtmlParser(item.copy)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }

  optionalBulletSection(postAcf) {
    if (postAcf.bullet_list_headline === '') {
      return '';
    } else {
      return (
        <div class="bullet-wrapper">
          <h2>{postAcf.bullet_list_headline}</h2>
          <div class="bullet-container">
            <ul>
              {postAcf.bullet_list_1.map(item => {
                return item.list_item_url ? (
                  <a href={item.list_item_url}>
                    <li className="copy">{item.list_item}</li>
                  </a>
                ) : (
                  <li className="copy">{item.list_item}</li>
                );
              })}
            </ul>
            <ul>
              {postAcf.bullet_list_2.map(item => {
                return item.list_item_url ? (
                  <a href={item.list_item_url}>
                    <li className="copy">{item.list_item}</li>
                  </a>
                ) : (
                  <li className="copy">{item.list_item}</li>
                );
              })}
            </ul>
            <ul>
              {postAcf.bullet_list_3.map(item => {
                return item.list_item_url ? (
                  <a href={item.list_item_url}>
                    <li className="copy">{item.list_item}</li>
                  </a>
                ) : (
                  <li className="copy">{item.list_item}</li>
                );
              })}
            </ul>
          </div>
        </div>
      );
    }
  }

  displayDemoForm(display, hubspot_form_id) {
    if (display) {
      return (<DemoFormFormikVertical hubspot_form_id={hubspot_form_id} />);
    } else {
      return '';
    }
  }

  displayAwards(post) {
    return (
      <div className="page-container">
        <Awards data={post.acf.awards} />
      </div>
    );
  }

  displayGlobalClients(post) {
    return (
      <div className="page-container">
        <StyledClients
          headline={post.acf.client_logo_headline}
          logos={post.acf.client_logos}
          quote={ReactHtmlParser(post.acf.quote)}
        />
      </div>
    );
  }

  optionalGlobalPartnersSection(postAcf) {
    if (postAcf.partner_logo_headline === '') {
      return '';
    } else {
      return (
        <GlobalOurPartners
          headline={postAcf.partner_logo_headline}
          logos={postAcf.partner_logos}
        />
      );
    }
  }

  displayHero(post) {
    if (
      post.acf.hero_headline === null ||
      post.acf.hero_sub_headline === null
    ) {
      return <h1>{ReactHtmlParser(post.title)}</h1>;
    } else {
      return (
        <Hero
          headline={post.acf.hero_headline}
          sub={post.acf.hero_sub_headline}
        />
      );
    }
  }

  render() {
    let post = this.props.data.wordpressWpOurVerticals;
    const additionalItems = post.acf.additional_item_title;
    const ALTERNATE_ORDER = !post.acf.component_alternate_order ? null :  post.acf.component_alternate_order;
    let additionalItemsArray = [];
    additionalItems.forEach(item => {
      additionalItemsArray.push({
        title: item.item_title,
        cta: item.item_cta,
        ctaURL: item.item_cta_url
      });
    });
    if (ALTERNATE_ORDER) {}
    return (
      <SingleVerticalPage className="animate">
        <SEO
          isBlogPost={false}
          postData={post}
          postImage={DefaultSharingImage}
        />

        <div className="page-container">

          <section className="hero-container">
            <div className="row">
              <div className={post.slug === 'finance-insurance' ? "col-lg-7 pr-0" : "col-lg-6"}>
                {this.displayHero(post)}
              </div>
              <div className={post.slug === 'finance-insurance' ? "col-md-6 col-lg-5 img-section right" : "col-md-6 img-section right"}>
                  <div className="d-none d-lg-block">
                    <img src={post.acf.verticals_intro_image.source_url} alt="Play Video" />
                  </div>
              </div>
            </div>
          </section>

          <p className="intro-copy">{ReactHtmlParser(post.acf.intro_copy)}</p>
          {post.acf.numbers_headline === '' || post.acf.numbers_items.numbers_item === '' ? (<></>)
          :
            (<div className="by-the-numbers">
              <h2 className="numbers-headline">{post.acf.numbers_headline}</h2>
              <div className="numbers-items-wrapper">
                {post.acf.numbers_items.map(item => {
                  return (
                    <div className="numbers-items">
                      <p className="numbers-number">{item.numbers_number}</p>
                      <p className="numbers-item">{item.numbers_item}</p>
                    </div>
                  );
                })}
              </div>
            </div>)
          }

          {post.acf.watch_video_link ? (
            <>
              <h3>{ReactHtmlParser(post.acf.video_heading)}</h3>
              <ModalVideo
                channel={this.parseVideo(post.acf.watch_video_link).type}
                isOpen={this.state.isOpen}
                videoId={this.parseVideo(post.acf.watch_video_link).id}
                onClose={() => this.setState({ isOpen: false })}
              />
                <a
                  className={this.props.formatType + ' clickable'}
                  onClick={this.openModal.bind(this)}
                >
                  Watch Video
                  <FontAwesomeIcon icon={faCaretRight} size="1x" />
                </a>

            </>
          ) : (
            <></>
          )}
        </div>

        {!ALTERNATE_ORDER === true ? this.displayAwards(post) : this.displayGlobalClients(post)}

        {/* <div className="page-container">
          <h2 className="centered">Image Slider</h2>
        </div> */}

        <div className="page-container full-width">
          {post.acf.create_slider.map((item, i) => {
              return (<DefaultSliderSolutionsDynamic
                  data={item.default_slider}
                  headline={item.slider_headline}
                  type={post.acf.slider_type}
                  index={i}
                  bgcolor="black"
                />
            )
          })}
        </div>

        <div className="post-area page-container">
          <div className="row">
            <div className="col-12">
              <h2 className="centered">{ReactHtmlParser(post.acf.landing_article_main_headline)}</h2>
            </div>
          </div>
          <div className="row page-container">
            <div className="blog-item col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-5">
              <React.Fragment>
                <div className="image-box">
                  <Link to={ReactHtmlParser(post.acf.landing_article_cta_url)} className="post-title">
                    <img src={post.acf.landing_article_image.source_url} alt="Article one" />
                  </Link>
                </div>
                <div className="post-details">
                  <h4>
                    <Link to={ReactHtmlParser(post.acf.landing_article_cta_url)} className="post-title">
                      {ReactHtmlParser(post.acf.landing_article_title)}
                    </Link>
                  </h4>
                  <div className="post-excerpt">
                    {ReactHtmlParser(post.acf.landing_article_copy)}
                  </div>
                  <div className="post-link">
                    <ButtonGrey
                      textLabel={ReactHtmlParser(post.acf.landing_article_cta)}
                      pageLink={ReactHtmlParser(post.acf.landing_article_cta_url)}
                      className="whiteCTA"
                    />
                  </div>
                </div>
              </React.Fragment>
            </div>

            <div className="blog-item col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-5">
              <React.Fragment>
                <div className="image-box">
                  <Link to={ReactHtmlParser(post.acf.landing_article_cta_url_two)} className="post-title">
                    <img src={post.acf.landing_article_image_two.source_url} alt="Article Two" />
                  </Link>
                </div>
                <div className="post-details">
                  <h4>
                    <Link to={ReactHtmlParser(post.acf.landing_article_cta_url_two)} className="post-title">
                      {ReactHtmlParser(post.acf.landing_article_title_two)}
                    </Link>
                  </h4>
                  <div className="post-excerpt">
                    {ReactHtmlParser(post.acf.landing_article_copy_two)}
                  </div>
                  <div className="post-link">
                    <ButtonGrey
                      textLabel={ReactHtmlParser(post.acf.landing_article_cta_two)}
                      pageLink={ReactHtmlParser(post.acf.landing_article_cta_url_two)}
                      className="whiteCTA"
                    />
                  </div>
                </div>
              </React.Fragment>
            </div>

            <div className="blog-item col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <ul className="list-unstyled">
                {additionalItemsArray.map((item) => {
                  return <li className="list-item"><h4>{item.title}</h4><ButtonGrey textLabel={item.cta} externalLink={item.ctaURL} className="whiteCTA" /></li>;
                })}
              </ul>
            </div>
          </div>

        </div>
        {!ALTERNATE_ORDER === true ? this.displayGlobalClients(post) : this.displayAwards(post)}

        <div className="page-container">
          {this.displayDemoForm(post.acf.display_demo_form, post.acf.hubspot_form_id)}
        </div>

      </SingleVerticalPage>
    );
  }
}

export const query = graphql`
  query($slug: String!) {
    wordpressWpOurVerticals(slug: { eq: $slug }) {
      slug
      title
      type
      yoast_meta {
        yoast_wpseo_canonical
        yoast_wpseo_metadesc
        yoast_wpseo_title
      }
      content
      acf {
        awards {
          award_copy
          award_headline
          award_link
          award_image {
            source_url
          }
        }
        hubspot_form_id
        numbers_headline
        numbers_items {
          numbers_item
          numbers_number
        }
        component_alternate_order
        verticals_intro_image {
          source_url
        }
        video_heading
        watch_video_link
        client_logo_headline
        hero_headline
        hero_sub_headline
        intro_copy
        item_cta_url
        landing_article_copy_two
        landing_article_copy
        landing_article_cta
        landing_article_cta_two
        landing_article_cta_url
        landing_article_cta_url_two
        landing_article_main_headline
        landing_article_title
        landing_article_title_two
        landing_article_image {
          title
          source_url
        }
        landing_article_image_two {
          title
          source_url
        }
        additional_item_title {
          item_title
          item_cta
          item_cta_url
        }
        quote
        seo_canonical
        seo_description
        seo_title
        slider_type
        create_slider {
          default_slider {
            slider_copy
            slider_cta_text
            slider_label
            slider_cta_url
          }
          slider_headline
        }
        display_demo_form
        client_logos {
          source_url
          slug
          title
        }
      }
    }
  }
`;

export default VerticalPageTemplate;
