import React, { Component } from 'react';
//FORMIK SPECIFIK
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
//STYLE
import styled from 'styled-components';
import { colors, fonts, breakpoints } from '../style-utilities/variables';

const StyledFormikForm = styled.div`
  margin: 6rem auto;

  h2 {
    text-align: center;
  }
  form {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    text-align: center;
    max-width: 650px;
    margin: 0 auto;
  }
  .form-group {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    line-height: 1.5rem;
    input {
      border: 1.4px solid #cfd4d9;
      padding: 0.6rem;
      border-radius: 4px;
    }
    label {
      color: #212629;
      font-weight: bold;
    }
  }
  .button-align {
    text-align: center;
    width: 100%;
  }
  .sideBySide {
    display: flex;
    flex-direction: column;
    @media (min-width: ${breakpoints.tabletMax}) {
      flex-direction: row;
      justify-content: space-between;
      .names,
      .emailphone {
        flex-basis: 47%;
      }
    }
  }
  #message {
    width: 100%;
    textarea {
      overflow: hidden;
      border: 1px solid ${colors.primaryGray};
      height: 200px;
      max-height: 200px;
      border-radius: 4px;
      overflow-y: auto;
    }
  }
  .success-message {
    padding: 15px;
    text-align: center;
    margin-bottom: 4rem;
    font-size: 20px;
  }

  .field-error {
    position: absolute;
    font-size: 0.8rem;
    top: 99%;
    color: ${colors.primary};
  }

  .submit-btn {
    background-color: ${colors.primary};
    border: 1px solid ${colors.primary};
    color: ${colors.white};
    font-weight: bold;
    border-radius: 25px;
    padding: 13px 25px;
    letter-spacing: 3px;
    cursor: pointer;
    align-self: center;
    justify-self: center;
    :hover {
      background-color: #9c2627;
      transition: 0.3s all ease;
    }
  }
  @media (min-width: ${breakpoints.tabletMax}) {
    margin: 8rem auto 9rem;
  }
`;

const initialValues = {
    first_last_name: '',
    email: '',
    company: ''
};

const Fieldset = ({ name, label, children, classname, id, ...rest }) => (
  <div className={classname} id={id}>
    <label htmlFor={name}>{label}</label>
    <Field id={name} name={name} {...rest}>
      {children}
    </Field>
    <ErrorMessage name={name} component="div" className="field-error" />
  </div>
);

class DemoFormFormikVertical extends Component {
  state = {
    success: false,
    successMsg: '',
    isBrowser: true,
    windowObject: null
  };

  componentDidMount() {
    this.setState({
      isBrowser: typeof window !== 'undefined'
    });
    if (this.state.isBrowser) {
      this.setState({ windowObject: window });
    }
  }

  render() {
    const hubspot_endpoint = this.props.hubspot_form_id ? `https://api.hsforms.com/submissions/v3/integration/submit/3224127/${this.props.hubspot_form_id}` : `https://api.hsforms.com/submissions/v3/integration/submit/3224127/6bba402d-9f96-45f4-ad6c-8ff126cfbc8f`;
    // NOTE: This component was duplicated to use on the Verticals page/template only.
    return (
      <StyledFormikForm>
        <h2>Connect With An Expert</h2>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            first_last_name: Yup.string()
              .trim()
              .required('Please complete this field.'),
            email: Yup.string()
              .email('Please complete this field.')
              .required('Please complete this field.'),
            company: Yup.string()
              .trim()
              .required('Please complete this field.')
          })}
          onSubmit={(values, actions) => {
            fetch(
              hubspot_endpoint,
              {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  context: {
                    pageUri: this.state.windowObject.location.href,
                    pageName: 'Sub Services'
                  },
                  fields: [
                    {
                      name: 'first_last_name',
                      value: values.first_last_name
                    },
                    {
                      name: 'email',
                      value: values.email
                    },
                    {
                        name: 'company',
                        value: values.company
                      }
                  ]
                })
              }
            )
              .then(response => {
                return response.json();
              })
              .then(data => {
                if (data) {
                  this.setState({
                    success: true,
                    successMsg:
                      "Thanks for reaching out. Be on the lookout for a message from our team."
                  });
                }
              });

            //done submitting, set submitting to false
            actions.setSubmitting(false);
            actions.resetForm(initialValues);
          }}
        >
          {({ errors, values, actions }) => (
            <div>
              <p
                className="success-message"
                style={{ display: this.state.success ? 'block' : 'none' }}
              >
                {this.state.successMsg}
              </p>
              <Form style={{ display: this.state.success ? 'none' : 'block' }}>
                  <div className="row">
                    <div className="col-xs-12 col-md-6 col-lg-4 col-xl-4">
                        <Fieldset
                            classname="form-group names"
                            name="first_last_name"
                            label="Name"
                            type="text"
                            placeholder=""
                        />
                    </div>
                    <div className="col-xs-12 col-md-6 col-lg-4 col-xl-4">
                        <Fieldset
                            classname="form-group names"
                            name="email"
                            label="Email"
                            type="text"
                            placeholder=""
                        />
                    </div>
                    <div className="col-xs-12 col-md-6 col-lg-4 col-xl-4">
                        <Fieldset
                            classname="form-group names"
                            name="company"
                            label="Company"
                            type="text"
                            placeholder=""
                        />
                    </div>
                </div>
                <div className="button-align">
                  <button type="submit" className="submit-btn">
                    Get In Touch
                  </button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </StyledFormikForm>
    );
  }
}

export default DemoFormFormikVertical;
