import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { breakpoints, colors, fonts } from './../../style-utilities/variables';
import Related3Grid from './../../components/Related/Related3Grid';

const RelatedContentTechnology = () => {
  return (
    <StaticQuery
      query={CASE_NEWS_BLOG_TECHQUERY}
      render={data => {
        //eliminate the dummy data
        const noDummy = edges =>
          edges.filter(edge => edge.node.slug !== 'do-not-delete');
        //retrieve only the results where technology is not an empty array
        const filterForTech = arr =>
          arr.filter(item => item.node.technology.length !== 0);

        const arrayMixed = [];
        const arrayAll = [];

        if (data.allWordpressWpCaseStudy) {
          data.allWordpressWpCaseStudy.edges.forEach(function(o) {
            arrayAll.push(o);
          });
        }
        if (data.allWordpressPost) {
          data.allWordpressPost.edges.forEach(function(o) {
            arrayAll.push(o);
          });
        }
        if (data.allWordpressWpWhitePaper) {
          data.allWordpressWpWhitePaper.edges.forEach(function(o) {
            arrayAll.push(o);
          });
        }

        arrayAll.sort(function(a, b) {
          return new Date(b.node.date) - new Date(a.node.date);
        });

        arrayMixed.push(arrayAll[0].node);
        arrayMixed.push(arrayAll[1].node);
        arrayMixed.push(arrayAll[2].node);
        return <Related3Grid header="Read These Next" items={arrayMixed} />;
      }}
    />
  );
};

//Topic#: 39
//Name: Ansira Edge
//Load all Ansira Edge topics and sort by date (newest to oldest)
const CASE_NEWS_BLOG_TECHQUERY = graphql`
  query {
    allWordpressWpCaseStudy(
      filter: { topic: { in: [39] } }
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          title
          type
          topic
          slug
          technology
          date(formatString: "MMMM D, YYYY")
          better_featured_image {
            source_url
            alt_text
          }
          acf {
            excerpt
            client
          }
        }
      }
    }
    allWordpressPost(
      filter: { topic: { in: [39] } }
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          title
          type
          topic
          slug
          date(formatString: "MMMM D, YYYY")
          technology
          author {
            name
            slug
          }
          better_featured_image {
            source_url
            alt_text
          }
          acf {
            excerpt
          }
        }
      }
    }
    allWordpressWpNews(
      filter: { topic: { in: [39] } }
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          title
          type
          topic
          slug
          technology
          date(formatString: "MMMM D, YYYY")
          better_featured_image {
            source_url
            alt_text
          }
          acf {
            excerpt
            hero_sub_headline
          }
        }
      }
    }
    allWordpressWpWhitePaper(
      filter: { topic: { in: [39] } }
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          title
          type
          topic
          slug
          date(formatString: "MMMM D, YYYY")
          technology
          better_featured_image {
            source_url
            alt_text
          }
          acf {
            excerpt
          }
        }
      }
    }
  }
`;
export default RelatedContentTechnology;
