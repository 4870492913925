import React from "react";
import { Link, StaticQuery } from "gatsby";
import ReactHTMLParser from "react-html-parser";
import styled from 'styled-components';

import ButtonGrey from "../ButtonGrey";
import Tag from "../Tag";
import { colors, breakpoints } from "../../style-utilities/variables";

import StyledRelatedGridItem from "./RelatedGridItem.styles";

function randID() {
  var number = Math.random(); // 0.9394456857981651
  number.toString(36);
  var id = number.toString(36).substr(2, 9);
  return id;
}

const StyledTitle = styled.h2`
text-transform: none;
font-size: 22px;
`;
const RelatedGridItem = ({
  item,
  index,
  gridCardType,
  path,
  buttonLabel,
  tagCategory,
  text
}) => {
  var topicIDs = item.topic;
  var topicNumber = "";
  var relatedTopic = "";
  topicIDs.slice(0, 1).map((item, index) => {
    topicNumber = topicIDs[index];
  });

  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressWpTopic {
            edges {
              node {
                id
                name
                description
                wordpress_id
              }
            }
          }
        }
      `}
      render={data => {
        const tags = data.allWordpressWpTopic;
        var taglabels = [];
        for (var key in tags.edges) {
          taglabels[tags.edges[key].node.wordpress_id] =
            tags.edges[key].node.name;
        }

        relatedTopic = taglabels[topicNumber];
        return (
          <StyledRelatedGridItem key={item.slug}>
            <div className="card-image">
              {item.better_featured_image ? (
                <Link to={path + item.slug}>
                  <img
                    id="client-image"
                    src={
                      item.better_featured_image
                        ? item.better_featured_image.source_url
                        : ""
                    }
                    alt={
                      item.better_featured_image
                        ? item.better_featured_image.alt_text
                        : ""
                    }
                  />
                </Link>
              ) : null}
            </div>

            <div className="card-content">
              {gridCardType === "showTags" ? (
                <p className="category">
                  <Tag
                    tag={relatedTopic}
                    tagCategory={tagCategory}
                    color={colors.white}
                    borderColor={colors.primaryGray}
                    textColor={colors.primaryGray}
                    key={index}
                    text={text ? text : relatedTopic}
                  />
                </p>
              ) : null}
              <Link to={path + item.slug} className="clickableOnly">
                <StyledTitle className="card-headline">{item.title}</StyledTitle>
              </Link>
              <div className="card-excerpt">
                <Link to={path + item.slug} className="clickableOnly">
                  {item.acf ? ReactHTMLParser(item.acf.excerpt) : null}{" "}
                </Link>
              </div>

              <ButtonGrey
                id="btn-grey"
                textLabel={buttonLabel}
                pageLink={path + item.slug}
              />
            </div>
          </StyledRelatedGridItem>
        );
      }}
    />
  );
};

export default RelatedGridItem;
